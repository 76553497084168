import { Link, useIntl } from "gatsby-plugin-intl";
import * as React from "react";

interface IProps {
  isHome?: boolean;
}

const Footer = (props: IProps) => {
  const { isHome = true } = props;
  const { formatMessage: fm } = useIntl();
  return (
    <div
      className={`relative z-40 bg-black ${
        isHome ? "lg:absolute" : ""
      } bottom-0 left-0 right-0 flex items-center`}
    >
      <section className="container px-4 lg:px-0 relative bottom-0 text-sm mx-auto text-white border-t border-white border-opacity-25 flex justify-between items-center text-opacity-30 py-5">
        &copy; {new Date().getFullYear()} Steppe Link Holding. All Rights
        Reserved
      </section>
      <div className="mr-0 lg:mr-20 cursor-pointer ">
        <Link
          className="hover:text-gray-100 text-gray-400 duration-500"
          to={"/privacy-policy"}
        >
          <div className="w-40">{fm({ id: "menu-privacy-policy" })}</div>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
