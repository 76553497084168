import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import scrollLock from "scroll-lock";
import * as React from "react";
import { Link } from "gatsby";
import Menu from "../Menu";
import { useLocation, useMatch } from "@reach/router";

import { useEffect } from "react";
import { useState } from "react";
import sal from "sal.js";

interface IProps {
  active?: boolean;
  inverse?: boolean;
}
/*
 "menu-home": "Home",
  "menu-about": "About",
  "menu-projects": "Projects",
  "menu-social": "Social responsibility",
  "menu-hr": "Human resources",
  "menu-news": "News",
  "menu-contact": "Contact"
*/
const Header = ({ active, inverse }: IProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const MenuSh = () => {
    if (window.scrollY >= 150) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", MenuSh);
    }
  }, []);
  const { formatMessage: fm } = useIntl();

  const [started, setStarted] = useState(false);
  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", (e) => {
        setStarted(window.scrollY > 0);
      });
    }
  }, []);

  useEffect(() => {
    console.log("STARTED: ", started);
  }, [started]);

  useEffect(() => {
    sal().update();
  }, []);

  const { locale } = useIntl();

  const location = useLocation();
  const isHome = useMatch(`/${locale}`);
  console.log("isHome", isHome);
  console.log("location: ", location);
  return (
    <>
      <div className={`absolute w-full top-0 left-0 right-0 z-50 `}>
        <section
          className={`container relative px-4 mx-auto flex items-center my-10`}
        >
          {/* {hide ? (
          <StaticImage
            src="../../images/icon.png"
            alt="Steppe Link Holding"
            height={55}
          />
        ) : ( */}
          <Link to={`/${locale}`} className="relative z-20">
            <div
              data-sal="slide-down"
              data-sal-delay="200"
              data-sal-duration={2000}
              data-sal-easing="easeInOutQuad"
              data-sal-repeat={started}
            >
              {isHome ? (
                <StaticImage
                  src="../../images/logo-white.png"
                  alt="Steppe Link Holding"
                  height={40}
                  placeholder="tracedSVG"
                />
              ) : inverse ? (
                <StaticImage
                  src="../../images/logo.png"
                  alt="Steppe Link Holding"
                  height={40}
                  placeholder="tracedSVG"
                />
              ) : (
                <StaticImage
                  src="../../images/logo-white.png"
                  alt="Steppe Link Holding"
                  height={40}
                  placeholder="tracedSVG"
                />
              )}
            </div>
          </Link>
        </section>
      </div>
      <div
        // className={
        //   showMenu
        //     ? "transition-all relative z-50"
        //     : "transition-all opacity-0 invisible z-50"
        // }
        className="transition-all relative z-50 "
      >
        <Menu />
      </div>
    </>
  );
};

export default Header;
