import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import scrollLock from "scroll-lock";
import QueueAnim from "rc-queue-anim";
import { Link, useIntl } from "gatsby-plugin-intl";
import "./style.css";
import { useLocation, useMatch } from "@reach/router";
const Menu = () => {
  const { formatMessage: fm } = useIntl();
  const location = useLocation();
  const isHome =
    ["/en", "/mn", "/", "/en/", "/mn/"].indexOf(location.pathname) !== -1;

  const menus = [
    {
      title: fm({ id: "menu-home" }),
      active: true,
      href: "/",
      key: "t1",
    },
    {
      title: fm({ id: "menu-about" }),
      href: "/about",
      key: "t2",
    },
    {
      title: fm({ id: "menu-projects" }),
      href: "/companies",
      key: "t3",
    },
    {
      title: fm({ id: "menu-social" }),
      href: "/social-responsibility",
      key: "t4",
    },
    {
      title: fm({ id: "menu-hr" }),
      href: "/hr",
      key: "t5",
    },
    // {
    //   title: fm({ id: "menu-privacy-policy" }),
    //   href: "/privacy-policy",
    //   key: "t6",
    // },
    // {
    //   title: fm({ id: "menu-news" }),
    //   href: "/news",
    //   key: "t6",
    // },
    {
      title: fm({ id: "menu-contact" }),
      href: "/contact",
      key: "t7",
    },
  ];
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      scrollLock.hide();
    } else {
      scrollLock.show();
    }
  }, [open]);

  return (
    <>
      <div
        className="top-5 fixed z-50  right-5 font-cool cursor-pointer"
        onClick={handleOpen}
      >
        <div className="block w-11 absolute left-1/2 top-1/2  transform  -translate-x-1/2 -translate-y-1/2 ">
          <span
            className={`block absolute bg-main  transform transition duration-500 ease-in-out ${
              open ? "rotate-45 scale-75" : "-translate-y-1.5"
            }`}
            style={{ height: "0.18rem", width: "2.6rem" }}
          />
          <span
            className={`block absolute bg-main transform transition duration-500 ease-in-out ${
              open ? "opacity-0 invisible " : ""
            }`}
            style={{ height: "0.18rem", width: "2.6rem" }}
          />
          <span
            className={`block absolute bg-main transform transition  duration-500 ease-in-out ${
              open ? "-rotate-45 scale-75" : "translate-y-1.5"
            }`}
            style={{ height: "0.18rem", width: "2.6rem" }}
          />
        </div>
        <div className="flex item-center">
          <span
            className={`absolute top-5 right-20 text-gray-100 font-serif flex duration-500 transform transition-all ${
              open ? "opacity-100 scale-100" : "opacity-0 invisible scale-125  "
            }`}
          >
            Close
          </span>

          <div
            className={`border rounded-full w-16 h-16 border-gray-500 hover:border-gray-600 flex duration-1000 items-center justify-center transform transition-all ${
              open ? "opacity-100 scale-100" : "opacity-0 invisible scale-125 "
            }`}
          ></div>
        </div>
      </div>
      <div
        className={`transition-all fixed top-0 right-0 left-0 bottom-0  font-cool z-40 ${
          open ? "opacity-100 " : "opacity-0 invisible"
        }`}
      >
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
          <StaticImage
            src="../../images/burger menu.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"
          onClick={handleOpen}
        ></div>
        <div className="container mx-auto px-4 ">
          <div className="flex flex-col justify-start mt-40 list-none bold font-serif tracking-normal h-full w-full lg:w-1/2 mx-auto text-gray-400 ">
            {open && (
              <QueueAnim
                delay={300}
                className="space-y-8 text-center lg:text-left"
              >
                {menus.map((menu) => (
                  <li key={menu.key}>
                    <Link
                      className="hover:text-gray-100 duration-500 text-lg md:text-2xl lg:text-4xl"
                      to={menu.href}
                    >
                      {menu.title}
                    </Link>
                  </li>
                ))}
                <div
                  className="lang text-xl inline-flex space-x-4 text-gray-400 mt-32 "
                  style={{}}
                  key="t-8"
                >
                  <a
                    className="relative langu hover:text-gray-100 duration-500 "
                    href="/mn"
                  >
                    mn
                  </a>
                  <span>/</span>
                  <a
                    className="relative langu hover:text-gray-100 duration-500 "
                    href="/en"
                  >
                    en
                  </a>
                </div>
              </QueueAnim>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
